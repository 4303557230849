import React, { useContext } from 'react'
import renderBloks from '@renderBloks'
import { Box } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ACC_THEME from '../../themes'
import PageContext from '@context'
import MeetupDetail from './meetup-detail'
import Layout from '../layout'

const MeetupsIndex = (props) => {
  const pageContext = useContext(PageContext)
  const { metaData, body, postBody, canonicalPage } = props.blok
  const meetUpsComing = !!pageContext.meetUps && pageContext.meetUps.sort()
  return (
    <Layout metaData={metaData} canonicalPage={canonicalPage}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        {!!body && !!body.length > 0 && renderBloks(body)}
        <Box>
          {!!meetUpsComing &&
            meetUpsComing
              .slice()
              .map(
                ({
                  uuid,
                  content: {
                    title,
                    guestSpeakers,
                    signUp,
                    meetupDate,
                    description,
                    isAutodeskUniversityMeetup,
                  },
                }) => {
                  return (
                    <Box key={uuid}>
                      <MeetupDetail
                        title={title}
                        guestSpeakers={guestSpeakers}
                        signUp={signUp}
                        meetupDate={meetupDate}
                        description={description}
                        isAutodeskUniversityMeetup={isAutodeskUniversityMeetup}
                      />
                    </Box>
                  )
                }
              )}
        </Box>
        {!!postBody && !!postBody.length > 0 && renderBloks(postBody)}
      </ThemeProvider>
    </Layout>
  )
}

export default MeetupsIndex
