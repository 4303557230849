import React, { useContext } from 'react'
import renderBloks from '@renderBloks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { H3, H5, ContentContainer } from '@system'
import PageContainer from '@system/page-container'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getFormattedDateTime } from '@helpers'
import ACC_THEME from '@themes'
import PageContext from '@context'
import renderBlok from '@renderBlok'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  outer: {
    height: '100%',
    display: 'flex',
    gap: '51px',
    [ACC_THEME.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [ACC_THEME.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '25px',
    },
  },
  columnInnerDate: {
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    maxWidth: '17%',
    display: 'flex',
  },
  columnInnerDateBox: {
    height: '165px',
    background: ACC_THEME.palette.gradient.sharp,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '3px',
  },
  columnInnerDateMonth: {
    color: '#FFF',
    fontSize: '28px',
    lineHeight: '35px',
    fontWeight: '300',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  columnInnerDateDay: {
    color: '#FFF',
    fontSize: '30px',
    lineHeight: '35px',
    fontWeight: '700',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  columnInnerDescription: {
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
  },
  [ACC_THEME.breakpoints.up('md')]: {
    columnInnerDescriptionGuest: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  subtitle: {
    marginTop: '0px',
    fontSize: '24px',
    color: ACC_THEME.palette.primary.main,
    display: 'block',
    lineHeight: '32px',
  },
  container: ({ isAutodeskUniversityMeetup, blok }) => ({
    padding: '60px 70px',
    [ACC_THEME.breakpoints.down('sm')]: {
      padding: '30px 70px',
    },
    backgroundColor: isAutodeskUniversityMeetup
      ? ACC_THEME.palette.septenary.main
      : isAutodeskUniversityMeetup === false || blok === undefined
      ? ''
      : blok.isAutodeskUniversityMeetup
      ? ACC_THEME.palette.septenary.main
      : '',
  }),
  columnInnerAuLogo: ({ isAutodeskUniversityMeetup, blok }) => ({
    minHeight: `36px`,
    marginTop: 'auto',
    maxWidth: '146px',
    display: isAutodeskUniversityMeetup
      ? 'block'
      : isAutodeskUniversityMeetup === false || blok === undefined
      ? 'none'
      : blok.isAutodeskUniversityMeetup
      ? 'block'
      : 'none',
  }),
}))

const MeetupDetail = (props, isAutodeskUniversityMeetup) => {
  const classes = useStyles(props, isAutodeskUniversityMeetup)
  const { title, guestSpeakers, signUp, meetupDate, description } =
    props.blok || props

  const auLogo =
    '//a.storyblok.com/f/64835/x/e8ccf76b36/autodesk_university.svg'

  const pageContext = useContext(PageContext)
  const signUpButton = get(signUp, '[0]')
  return (
    <PageContainer>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <ContentContainer className={classes.container}>
          <Box className={classes.outer}>
            <Box className={classes.columnInnerDate}>
              <Box className={classes.columnInnerDateBox}>
                <H5 className={classes.columnInnerDateMonth}>
                  {getFormattedDateTime(meetupDate, 'MMMM')}
                </H5>
                <H5 className={classes.columnInnerDateDay}>
                  {getFormattedDateTime(meetupDate, 'D')}
                </H5>
              </Box>
              {!signUpButton && pageContext.meetUpFormCtaModule
                ? renderBlok(pageContext.meetUpFormCtaModule, {
                    modalFormFieldValues: {
                      meetupTitle: title,
                      meetupDate: meetupDate,
                    },
                  })
                : !!signUpButton && renderBloks(signUp)}

              <img
                src={auLogo}
                alt="Autodesk University"
                className={classes.columnInnerAuLogo}
              />
            </Box>

            <Box className={classes.columnInnerDescription}>
              <H3 className={classes.subtitle}>{title}</H3>
              {renderBloks(description)}
              <Box className={classes.columnInnerDescriptionGuest}>
                {renderBloks(guestSpeakers, { isMeetUpGuest: true })}
              </Box>
            </Box>
          </Box>
        </ContentContainer>
      </ThemeProvider>
    </PageContainer>
  )
}

export default MeetupDetail
